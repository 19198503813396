import { AlertCircle, AlertOctagon, AlertTriangle, AlignJustify, AlignLeft, Aperture, Archive, ArrowRight, ArrowUp, AtSign, BarChart, BarChart2, Bell, Book, Box, Calendar, Check, CheckCircle, CheckSquare, ChevronDown, ChevronLeft, ChevronRight, ChevronsRight, ChevronUp, Circle, Clock, Cloud, CloudDrizzle, CloudLightning, CloudRain, Code, Copy, CreditCard, Crop, Database, Disc, DollarSign, Download, Edit, Edit2, Edit3, ExternalLink, Eye, EyeOff, FastForward, Feather, File, FileText, Filter, Flag, Folder, Framer, Gift, Globe, Grid, Hash, HelpCircle, Home, Image, Info, Key, Layers, Layout, Link, Link2, List, Lock, LogIn, Mail, Map, MapPin, Maximize, Maximize2, Minus, MinusCircle, Monitor, MoreHorizontal, Move, Paperclip, PenTool, Percent, Phone, Play, Plus, PlusSquare, Printer, RefreshCw, Repeat, RotateCcw, Search, Send, Settings, Share2, ShoppingBag, ShoppingCart, Slash, Sliders, Square, Star, Sun, Tag as FeatherIconsTag, Target, Trash2, Truck, Type, Upload, UploadCloud, User, UserCheck, UserMinus, UserPlus, Video, X, XCircle } from 'angular-feather/icons';
import { AlarmOn } from '../svg/alarm-on';
import { ArrowsTwoWay } from '../svg/arrows-two-way';
import { Assign } from '../svg/assign';
import { Asterisk } from '../svg/asterisk';
import { Auditorium } from '../svg/auditorium';
import { Back } from '../svg/back';
import { Barcode } from '../svg/barcode';
import { Biking } from '../svg/biking';
import { BillOff } from '../svg/bill-off';
import { Blockchain } from '../svg/blockchain';
import { BlockchainService } from '../svg/blockchain-service';
import { Bolt } from '../svg/bolt';
import { Boxes } from '../svg/boxes';
import { Brush } from '../svg/brush';
import { Building } from '../svg/building';
import { Bus } from '../svg/bus';
import { Calculate } from '../svg/calculate';
import { CalendarDates } from '../svg/calendar-dates';
import { CalendarEvent } from '../svg/calendar-event';
import { CalendarProgram } from '../svg/calendar-program';
import { CalendarStar } from '../svg/calendar-star';
import { CalendarTime } from '../svg/calendar-time';
import { CarCrash } from '../svg/car-crash';
import { CarSide } from '../svg/car-side';
import { Card } from '../svg/card';
import { Cash } from '../svg/cash';
import { Coins } from '../svg/coins';
import { Coins2 } from '../svg/coins2';
import { Collapse } from '../svg/collapse';
import { Columns } from '../svg/columns';
import { Company } from '../svg/company';
import { CrmBarChart } from '../svg/crm-bar-chart';
import { CrmIcon } from '../svg/crm-icon';
import { CrmWarning } from '../svg/crm-warning';
import { Custom } from '../svg/custom';
import { Dashboard } from '../svg/dashboard';
import { Discount } from '../svg/discount';
import { DollarBill } from '../svg/dollar-bill';
import { DollarPlusMinus } from '../svg/dollar-plus-minus';
import { DoneCircle } from '../svg/done-circle';
import { DoorOpen } from '../svg/door-open';
import { Drag } from '../svg/drag';
import { Droplet } from '../svg/droplet';
import { Dumpster } from '../svg/dumpster';
import { Envelope } from '../svg/envelope';
import { Exclamation } from '../svg/exclamation';
import { ExclamationTriangle } from '../svg/exclamation-triangle';
import { Exclamation2 } from '../svg/exclamation2';
import { Expand } from '../svg/expand';
import { FillDrip } from '../svg/fill-drip';
import { Forward } from '../svg/forward';
import { Gallery } from '../svg/gallery';
import { Gear } from '../svg/gear';
import { GiftCard } from '../svg/gift-card';
import { GripVertical } from '../svg/grip-vertical';
import { Group } from '../svg/group';
import { History } from '../svg/history';
import { IdCard } from '../svg/id-card';
import { ImageLandscape } from '../svg/image-landscape';
import { Images } from '../svg/images';
import { InProgress } from '../svg/in-progress';
import { InfinityIcon } from '../svg/infinity';
import { InfoCircle } from '../svg/info-circle';
import { Key2 } from '../svg/key2';
import { Lightbulb } from '../svg/lightbulb';
import { LinesSquare } from '../svg/lines-square';
import { ListCheck } from '../svg/list-check';
import { Livestream } from '../svg/livestream';
import { ManualIcon } from '../svg/manual-icon';
import { MapGridded } from '../svg/map-gridded';
import { MapSigns } from '../svg/map-signs';
import { Monument } from '../svg/monument';
import { Nft } from '../svg/nft';
import { Parking } from '../svg/parking';
import { PauseCircle } from '../svg/pause-circle';
import { Payment } from '../svg/payment';
import { PhotoFilm } from '../svg/photo-film';
import { PriceOverride } from '../svg/price-override';
import { PublicSite } from '../svg/public-site';
import { Puzzle } from '../svg/puzzle';
import { QrCode } from '../svg/qr-code';
import { Quantitative } from '../svg/quantitative';
import { Quidditch } from '../svg/quidditch';
import { RectanglesMixed } from '../svg/rectangles-mixed';
import { Rhombus } from '../svg/rhombus';
import { Road } from '../svg/road';
import { Rocket } from '../svg/rocket';
import { Route } from '../svg/route';
import { Running } from '../svg/running';
import { Seedling } from '../svg/seedling';
import { Shapes } from '../svg/shapes';
import { ShoePrints } from '../svg/shoe-prints';
import { ShoppingCartOff } from '../svg/shopping-cart-off';
import { ShortDescription } from '../svg/short-description';
import { Shower } from '../svg/shower';
import { SpinnerCircle } from '../svg/spinner-circle';
import { SprayCan } from '../svg/spray-can';
import { SquareAdd } from '../svg/square-add';
import { SquareAddSmall } from '../svg/square-add-small';
import { Sub } from '../svg/sub';
import { Survey } from '../svg/survey';
import { Tab } from '../svg/tab';
import { Tag } from '../svg/tag';
import { Tax } from '../svg/tax';
import { Tenge } from '../svg/tenge';
import { TestQrCode } from '../svg/test-qr-code';
import { Thumbtack } from '../svg/thumbtack';
import { TicketOutline } from '../svg/ticket-outline';
import { Tint } from '../svg/tint';
import { Toolbox } from '../svg/toolbox';
import { Tools } from '../svg/tools';
import { TrafficLight } from '../svg/traffic-light';
import { Translation } from '../svg/translation';
import { Trash } from '../svg/trash';
import { TrashAlt } from '../svg/trash-alt';
import { Tree } from '../svg/tree';
import { UniversalAccess } from '../svg/universal-access';
import { UserGroup } from '../svg/user-group';
import { Users } from '../svg/users';
import { Vial } from '../svg/vial';
import { VideoCircle } from '../svg/video-circle';
import { Walking } from '../svg/walking';
import { Wallet } from '../svg/wallet';
import { Wheelchair } from '../svg/wheelchair';
import { WineBottle } from '../svg/wine-bottle';
import { Wrench } from '../svg/wrench';
import { XY } from '../svg/x-y';
import { Accessibility } from './../svg/accessibility';
import { Chair } from './../svg/chair';
import { Invoice } from './../svg/invoice';

export const UiKitIconPack = {
  Accessibility,
  AlarmOn,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignJustify,
  AlignLeft,
  Aperture,
  Archive,
  ArrowRight,
  ArrowUp,
  ArrowsTwoWay,
  Assign,
  Asterisk,
  AtSign,
  Auditorium,
  Back,
  BarChart,
  BarChart2,
  Barcode,
  Bell,
  Biking,
  BillOff,
  Blockchain,
  BlockchainService,
  Bolt,
  Book,
  Box,
  Boxes,
  Brush,
  Building,
  Bus,
  Calculate,
  Calendar,
  CalendarDates,
  CalendarEvent,
  CalendarProgram,
  CalendarStar,
  CalendarTime,
  CarCrash,
  CarSide,
  Card,
  Cash,
  Chair,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronsRight,
  Circle,
  Clock,
  Cloud,
  CloudDrizzle,
  CloudLightning,
  CloudRain,
  Code,
  Coins,
  Coins2,
  Collapse,
  Columns,
  Company,
  Copy,
  CreditCard,
  CrmBarChart,
  CrmIcon,
  CrmWarning,
  Crop,
  Custom,
  Dashboard,
  Database,
  Disc,
  Discount,
  DollarBill,
  DollarPlusMinus,
  DollarSign,
  DoneCircle,
  DoorOpen,
  Download,
  Drag,
  Droplet,
  Dumpster,
  Edit,
  Edit2,
  Edit3,
  Envelope,
  Exclamation,
  Exclamation2,
  ExclamationTriangle,
  Expand,
  ExternalLink,
  Eye,
  EyeOff,
  FastForward,
  Feather,
  FeatherIconsTag,
  File,
  FileText,
  FillDrip,
  Filter,
  Flag,
  Folder,
  Forward,
  Framer,
  Gallery,
  Gear,
  Gift,
  GiftCard,
  Globe,
  Grid,
  GripVertical,
  Group,
  Hash,
  HelpCircle,
  History,
  Home,
  IdCard,
  Image,
  ImageLandscape,
  Images,
  InProgress,
  InfinityIcon,
  Info,
  InfoCircle,
  Invoice,
  Key,
  Key2,
  Layers,
  Layout,
  Lightbulb,
  LinesSquare,
  Link,
  Link2,
  List,
  ListCheck,
  Livestream,
  Lock,
  LogIn,
  Mail,
  ManualIcon,
  Map,
  MapGridded,
  MapPin,
  MapSigns,
  Maximize,
  Maximize2,
  Minus,
  MinusCircle,
  Monitor,
  Monument,
  MoreHorizontal,
  Move,
  Nft,
  Paperclip,
  Parking,
  PauseCircle,
  Payment,
  PenTool,
  Percent,
  Phone,
  PhotoFilm,
  Play,
  Plus,
  PlusSquare,
  PriceOverride,
  Printer,
  PublicSite,
  Puzzle,
  QrCode,
  Quantitative,
  Quidditch,
  RectanglesMixed,
  RefreshCw,
  Repeat,
  Rhombus,
  Road,
  Rocket,
  RotateCcw,
  Route,
  Running,
  Search,
  Seedling,
  Send,
  Settings,
  Shapes,
  Share2,
  ShoePrints,
  ShoppingBag,
  ShoppingCart,
  ShoppingCartOff,
  ShortDescription,
  Shower,
  Slash,
  Sliders,
  SpinnerCircle,
  SprayCan,
  Square,
  SquareAdd,
  SquareAddSmall,
  Star,
  Sub,
  Sun,
  Survey,
  Tab,
  Tag,
  Target,
  Tax,
  Tenge,
  TestQrCode,
  Thumbtack,
  TicketOutline,
  Tint,
  Toolbox,
  Tools,
  TrafficLight,
  Translation,
  Trash,
  Trash2,
  TrashAlt,
  Tree,
  Truck,
  Type,
  UniversalAccess,
  Upload,
  UploadCloud,
  User,
  UserCheck,
  UserGroup,
  UserMinus,
  UserPlus,
  Users,
  Vial,
  Video,
  VideoCircle,
  Walking,
  Wallet,
  Wheelchair,
  WineBottle,
  Wrench,
  X,
  XCircle,
  XY,
};
