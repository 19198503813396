import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthUser } from '@core/models/auth/auth-user.model';
import { AAGUID, Country, CountryCode, Currency, DisplayImageOrientation, GeneralConfig, IPlaceModuleConfig, LanguageCode, OnlinePaymentMethod, PartnerConfig, PartnerPageWebConfig, PartnerType, SalesConfig, StoreService, TimeZone, UtilCoverImage, WebConfig } from '@interticket/core';
import { IUserProfile, IUserSettings } from '@services/iam-management-user';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthUserStoreService extends StoreService<AuthUser> {

  private readonly defaultState: AuthUser = new AuthUser({
    userProfile: null,
    partnerConfig: null,
    permissionList: [],
  });

  readonly partnerConfig$: Observable<PartnerConfig> = this.shareState(({ partnerConfig }) => partnerConfig);
  readonly userProfile$: Observable<IUserProfile> = this.shareState(({ userProfile }) => userProfile);

  constructor(
    @Inject(DOCUMENT) private document: Document,
  ) {
    super();
    this.resetState();
  }

  get coverImageOrientation(): DisplayImageOrientation {
    return this.web.partnerPageWebConfig?.coverImageOrientation || DisplayImageOrientation.PORTRAIT;
  }

  get coverImageRatio(): number {
    return UtilCoverImage.getCoverImageRatio(this.coverImageOrientation);
  }

  get userProfile(): IUserProfile {
    return this.state.userProfile;
  }

  get partnerConfig(): PartnerConfig {
    return this.state.partnerConfig;
  }

  set partnerConfig(partnerConfig: PartnerConfig) {
    this.patchState({ partnerConfig });
  }

  get partnerType(): PartnerType {
    return this.state.partnerConfig.partnerType;
  }

  get generalConfig(): GeneralConfig {
    return this.partnerConfig?.general;
  }

  get defaultTimezone(): TimeZone {
    return this.generalConfig?.defaultTimezone || TimeZone.LOCAL;
  }

  get defaultCurrency(): Currency {
    return this.generalConfig.defaultCurrency;
  }

  get defaultCountryCode(): CountryCode {
    return this.generalConfig.defaultCountryCode;
  }

  get countries(): Country[] {
    return this.generalConfig?.countries || [];
  }

  get enabledPaymentMethods(): OnlinePaymentMethod[] {
    return this.generalConfig.enabledPaymentMethods || [];
  }

  get partnerId(): AAGUID {
    return this.partnerConfig.partnerId;
  }

  get defaultLanguageCode(): LanguageCode {
    return this.generalConfig?.defaultLanguageCode?.toLowerCase() as LanguageCode || LanguageCode.EN;
  }

  get web(): WebConfig {
    return this.partnerConfig.web;
  }

  get admissionReportExportColumns(): string[] {
    return this.userProfile?.userSettings?.admissionReportExportColumns || [];
  }

  get transactionReportExportColumns(): string[] {
    const columns = this.userProfile?.userSettings?.transactionReportExportColumns || [];
    const { assignHolderToTickets, assignHolderToGiftCards } = this.salesConfig;

    if (assignHolderToTickets || assignHolderToGiftCards) {
      return columns;
    }

    return columns.filter(column => !column.startsWith('holder_'));
  }

  get giftCardReportExportColumns(): string[] {
    return this.userProfile?.userSettings?.giftCardReportExportColumns || [];
  }

  get financialReportExportColumns(): string[] {
    return this.userProfile?.userSettings?.financialReportExportColumns || [];
  }

  set userSettings(userSettings: IUserSettings) {
    if (!userSettings) {
      console.warn('Invalid user settings provided.');
      return;
    }

    this.patchState({ userProfile: { ...this.userProfile, userSettings } });
  }

  get permissionList(): string[] {
    return this.state.permissionList;
  }

  get salesConfig(): SalesConfig {
    return this.partnerConfig?.sales;
  }

  get defaultPlaceSettings(): IPlaceModuleConfig[] {
    return this.partnerConfig?.defaultPlaceSettings || [];
  }

  set defaultPlaceSettings(defaultPlaceSettings: IPlaceModuleConfig[]) {
    this.patchState({
      partnerConfig: {
        ...this.partnerConfig,
        defaultPlaceSettings,
      },
    });
  }

  get bookingPlaceId(): AAGUID {
    return this.partnerConfig.booking.placeId;
  }

  patchCoverImageSettings(coverImageOrientation: DisplayImageOrientation): void {
    const updatedWebConfig = new PartnerPageWebConfig({
      ...this.web.partnerPageWebConfig,
      coverImageOrientation,
    });

    const patchedConfig = {
      partnerConfig: {
        ...this.partnerConfig,
        web: {
          ...this.web,
          partnerPageWebConfig: updatedWebConfig,
        },
      },
    };

    this.patchState(patchedConfig);
    this.setCoverImageSettingsToWindow();
  }

  /**
   * Customer facing UI-kit uses window object to get cover image settings and display landing page previews in landing editor.
   */
  setCoverImageSettingsToWindow(): void {
    window['coverImageAspectRatio'] = this.coverImageRatio;
    window['coverImageOrientation'] = this.coverImageOrientation;
  }

  getPartnerSiteDomain(withHttps = false): string {
    const domain: string = this.web?.partnerPageWebConfig?.domain
      || this.web?.partnerPageWebConfig?.regionPartnerPageDomain
      || this.web?.regionSubPageWebConfig?.domain
      || '';

    return `${withHttps ? 'https://' : ''}${domain}`;
  }

  getHostDomain(withHttps = false): string {
    return `${withHttps ? 'https://' : ''}${this.document.location.host}`;
  }

  resetState(): void {
    this.patchState({ ...this.defaultState });
  }

}
